.instructor-profile-wrapper {
	margin: 40px 0 80px 0;
}

.instructor-heading {
	font-size: 32px;
	line-height: 39px;
	font-weight: 700;
	color: #1a1a1a;
	margin-bottom: 15px;
}

.instructor-label {
	font-size: 18px;
	line-height: 27px;
	color: #1a1a1a;
	margin-bottom: 40px;
}

.instructor-profile-custom-style {
	/* max-width: 200px;
	min-width: 100px;
	max-height: 250px;
	border-radius: 50%;
	object-fit: contain;
	width: 100%; */
	width: 160px;
	height: 160px;
	border-radius: 50%;
}
.initials-placeholder-helper {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	background-color: #a55bff;
	color: white;
	display: flex;
	margin-right: 4px;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
}
.instructor-name {
	font-size: 24px;
	line-height: 29px;
	color: #1a1a1a;
}
.initials-placeholder {
	width: 160px;
	height: 160px;
	border-radius: 50%;
	background-color: #a55bff;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 46px;
	font-weight: bold;
	text-transform: uppercase;
}

.instructor-expr {
	font-size: 14px;
	line-height: 21px;
	color: #1a1a1a;
	margin-bottom: 45px;
}

.instructor-intro {
	font-size: 18px;
	line-height: 27px;
	color: #1a1a1a;
}

.instructor-full-desc {
	font-size: 16px;
	line-height: 24px;
	color: var(--color17);
}

.instructor-desc-layout {
	margin-left: 45px;
}
@media only screen and (max-width: 992px) {
	.instructor-profile-wrapper {
		margin: 40px 0 20px 0;
	}
	.instructor-label {
		font-size: 16px;
		line-height: 24px;
		color: var(--color17);
		margin-bottom: 40px;
	}
	.instructor-desc-layout {
		margin-left: 0;
	}
	.instructor-expr {
		font-size: 14px;
		line-height: 21px;
		color: var(--color17);
		margin-bottom: 20px;
	}
	.instructor-full-desc {
		font-size: 16px;
		line-height: 24px;
		color: #757575;
	}
}

.mentorContainer {
	border-radius: 12px;
	border: 1px solid #d8d8d8;
}

.text-font-sizing {
	font-size: var(--fs-20);
}

@media only screen and (max-width: 576px) {
	.text-font-sizing {
		font-size: var(--fs-14);
	}
}
