.login-title {
  font-size: 32px;
  line-height: 39px;
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: -2px;
}

.header-top-layout {
  margin: 10px;
}

.login-new-label {
  font-size: 16px;
  line-height: 24px;
  color: #757575;
  margin-top: 12px !important;
  margin-bottom: -10px;
}

.login-new-label-span {
  font-size: 16px;
  line-height: 24px;
  /* color: #1a1a1a; */
  color: var(--primaryColor);
  font-weight: 700;
  text-decoration: underline;
}

.close-btn-style {
  font-size: 24px !important;
  color: #1a1a1a !important;
  margin-top: -20px;
}
.label-style {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  margin-bottom: 10px;
}

.btn.bg-color2:hover {
  background-color: #fecc00 !important;
}

.login-mes-text {
  font-size: 16px;
  line-height: 24px;
}

.new-btn-layout {
  padding: 14px 16px !important;
  border-radius: 12px !important;
  color: #1a1a1a;
  font-size: 16px !important;
  line-height: 24px;
  margin-top: -10px;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px 0;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #d8d8d8;
}

.divider:not(:empty)::before {
  margin-right: 1.25em;
}

.divider:not(:empty)::after {
  margin-left: 1.25em;
}

.cwg-style {
  border: 1px solid #d8d8d8 !important;
  padding: 14px 24px !important;
  color: #1a1a1a;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: -30px !important;
}

.body-layout {
  margin: 0px 15px;
}
.fg-style {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  text-decoration: none;
  margin-top: 16px;
}

.input-inner-style {
  padding: 14px 16px !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #1a1a1a !important;
}

.distance {
  margin-top: 25px !important;
}

.distance-bottom {
  margin-bottom: 10px !important;
}

.login-separator {
  margin-left: 11em !important;
}

#info-block section {
  border-top: 1px solid #888480 !important;
}

.veri-title {
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: #22211f;
  margin-top: 20px;
  margin-bottom: 12px;
}

.veri-label {
  font-size: 16px;
  line-height: 24px;
  color: #63615e;
  max-width: 450px;
  margin: auto;
  margin-bottom: 30px;
}

.veri-label-strong {
  color: #22211f;
}

.veri-desc {
  font-size: 14px;
  line-height: 21px;
  color: #888480;
}

.veri-desc-strong {
  color: #22211f;
}

.input-inner-veri-style {
  padding: 14px 16px !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #1a1a1a !important;
  /* border-color: var(--color17) !important; */
}
