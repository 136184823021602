.hover-white {
    color: white !important;
    background-color: black !important;

    :hover {
        color: white !important;
    }
}

.hover-black {
    color: black !important;
   background-color: white !important;

    :hover {
        color: black !important;
    }
}
.search-container {
	position: relative;
	display: flex;
	align-items: center;
}
.search-container.search-container-expanded{
    width: 50%;
}
.search-icon {
	cursor: pointer;
	transition: color 0.3s ease-in-out;
}

.search-input {
	width: 0 !important; 
	opacity: 0 !important;
	font-size: 16px !important;
	border: 1px solid #ccc !important;
    font-family: "g_mid";
	transition: width 20s ease-in-out, opacity 20s !important;
}

.search-input.expanded {
	width: 100% !important;
	opacity: 1 !important;
    display: block !important;
    position: relative !important;
    padding: 29px 24px !important;
}
.is-expanded-search-icon{
    position: absolute;
    top: 19px;
    right: 17px;
}